import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import "./Password.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useAppDispatch } from "../../Hooks";
import { setIsLogin, setPosConnect, setUserObject } from "../../store/reducers/AuthReducer";
import BGIMAGE from "../../assets/images/background.jpg";
import LOGO from "../../assets/images/logo_main_img.png";
import { Button } from "@mui/base";

function Password() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { email } = location.state;
  const [password, setPassword]: any = useState("");
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();

  const HandleLogin = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (password === "") {
      enqueueSnackbar("Please enter your password", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      return;
    }
    const Params = {
      email: email,
      password: password,
    };

    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.login,
        Params
      );
      console.log("USER===>",response?.user)
      if (response.status === true) {
        localStorage.setItem("referralCode", response.user?.referral_code);
        localStorage.setItem("IsLogin", response);
        localStorage.setItem("UserName", response.user?.name);
        localStorage.setItem("Email", response.user?.email);
        localStorage.setItem("UserCode", response.user?.user_code);
        dispatch(setPosConnect(response.user?.pos_account_status))
        dispatch(setUserObject(response?.user))
        console.log(response?.user,"PASSWORD")
        // localStorage.setItem("PosAccount", response.user?.pos_account_status);
        localStorage.setItem(
          "Subscription",
          response.user?.subscription_status
        );
        localStorage.setItem("UserID", response.user?.id);
        localStorage.setItem("Token", response.token);
        dispatch(setIsLogin(true));
      } else if (response.status === false) {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="container-login text-start">
      <div className="main-container-login">
        <h2 className="miniosk-login">
          Welcome back to your business account, Unassigned
        </h2>
        <p className="miniosk-login-desc">
          Enter Your Password To Log in As{" "}
          <span style={{ color: "#8B5CFF" }}>{email}</span>
        </p>
        <h3 className="email-miniosk">Password</h3>
        <form onSubmit={HandleLogin}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <input
              placeholder="Enter a password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "30vw",
                height: "8.5vh",
                borderRadius: "8px",
                color: "#000000",
                paddingLeft: "12px",
                border: "1px solid #201C1C3D",
                fontWeight: "400",
              }}
            />
            <div
              style={{
                position: "absolute",
                right: "22%",
                top: "50%",
                transform: "translateY(-50%)",
                alignItems: "center",
                display: "flex",
              }}
            >
              {showPassword ? (
                <VisibilityOffIcon onClick={togglePasswordVisibility} />
              ) : (
                <VisibilityIcon onClick={togglePasswordVisibility} />
              )}
            </div>
          </div>
          <p
            className="forgotpassword"
            onClick={() => Navigate("/forgetpassword")}
          >
            Forgot your password?
          </p>
          {/* <button className='continue-button' type='submit'>Login</button> */}
          <Button
            type="submit"
            className="continue-button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? "Loading..." : "Login"}
          </Button>
        </form>
        <div className="kiosk_imgDiv">
          <img src={LOGO} alt="logo" style={{ width: "150px" }} />
        </div>
      </div>
      <div style={{ height: "100vh", overflow: "hidden", width: "60%" }}>
        <img
          src={BGIMAGE}
          alt="bg"
          style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default Password;
