import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
  LinearProgress,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Edit from "../assets/images/editmenu.png";
import Delete from "../assets/images/delete.png";
import Modal from "@mui/material/Modal";
import Cross from "../assets/images/Cross.png";
import Emoji from "../assets/images/emoji.png";
import Plus from "../assets/images/plus.png";
import Switch from "@mui/material/Switch";
import ApiServices from "../helpers/ApiServices";
import HelperModule from "../helpers/HelperModule";
import { enqueueSnackbar } from "notistack";
import { Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../Hooks";
import { setLocationSelectedId } from "../store/reducers/LocationsReducer";
import CircularProgress from "@mui/material/CircularProgress";
import { setWebList } from "../store/reducers/ManageReducer";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import {
  FormControl,
  InputLabel,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
} from "@mui/material";
import DataTable from "./DataTable";

function createData(
  device_id: any,
  device_name: any,
  menu_name: any,
  location: any,
  total_orders: any,
  status: any
) {
  return { device_id, device_name, menu_name, location, total_orders, status };
}

interface TimeSlot {
  day_of_week: string;
  start_local_time: string;
  end_local_time: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: any = [
  {
    id: "location_name",
    disablePadding: true,
    label: "Location",
    sortable: true,
  },
  {
    id: "menu_count",
    disablePadding: false,
    label: "Menu",
    sortable: true,
  },
  {
    id: "category_count",
    disablePadding: false,
    label: "Categories",
    sortable: true,
  },
  {
    id: "all_items",
    disablePadding: false,
    label: "Items",
    sortable: true,
  },
  {
    id: 'web_options',
    disablePadding: false,
    label: "Web Option",
    sortable: false,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sortable: true,
  },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, controller } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={controller.field === headCell.id} // orderBy === headCell.id
              // direction={orderBy === headCell.id ? order : "asc"}
              direction={controller.order === "asc" ? "asc" : "desc"}
              disabled={!headCell.sortable}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let UserCodeData = localStorage.getItem("UserCode");

console.log(UserCodeData, "UserCodeData--------> Invalid");

const WebSettingsTable = ({ setFilterDisabled, selectedLocations }: any) => {
  const { WebList } = useSelector((state: any) => state.manageData);
  const { filteredLocations } = useSelector(
    (state: any) => state.locationDetails
  );

  const [recordsCount, setRecordsCount] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });

  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("location_name");
  const [webSettingsModal, setWebSettingsModal] = React.useState(false);
  const [failedModal, setFailedModal] = React.useState(false);
  const [manageWebSettings, setmanageWebSettings]: any = useState([]);
  const [status, setStatus]: any = useState("");
  const [menuName, setMenuName] = React.useState("");
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([
    { day_of_week: "", start_local_time: "", end_local_time: "" },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = manageWebSettings.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [filteredValues, setFilteredValues] = useState([]);
  const [loading, setLoading] = React.useState(true);
  const [tokenData, setToken] = useState("");
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [locationid, setLocationId] = useState<any>("");
  const [webOptions, setWebOptions] = useState<any>([]);



  const daysOfWeek = [
    { name: "Monday", value: "MON" },
    { name: "Tuesday", value: "TUE" },
    { name: "Wednesday", value: "WED" },
    { name: "Thursday", value: "THU" },
    { name: "Friday", value: "FRI" },
    { name: "Saturday", value: "SAT" },
    { name: "Sunday", value: "SUN" },
  ];

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      let days = daysOfWeek?.map((elem: any) => elem?.name);
      setSelectedDays(days);
      setSelectAll(true);
    } else {
      setSelectedDays([]);
      setSelectAll(false);
    }
  };

  const handleDaySelect = (event: SelectChangeEvent<typeof selectedDays>) => {
    const {
      target: { value },
    } = event;
    setSelectedDays(value as string[]);
    setSelectAll(value.length === daysOfWeek.length);
  };

  const handleAddTimeSlot = () => {
    setTimeSlots([...timeSlots, { day_of_week: "", start_local_time: "", end_local_time: "" }]);
  };

  const handleTimeSlotChange = (
    index: number,
    field: keyof TimeSlot,
    value: any
  ) => {
    let updatedTimeSlots = [...timeSlots];
    let val = updatedTimeSlots[index];
    val[field] = dayjs(value).format("HH:mm:ss");
    updatedTimeSlots.splice(index, 1, val);
    setTimeSlots([...updatedTimeSlots]);
  };

  // const [timings, setTimings]: any = useState({});

  // const handleTimeChange = (weekday: any, field: any, value: any) => {
  //   setTimings((prevTimings: any) => ({
  //     ...prevTimings,
  //     [weekday]: {
  //       ...prevTimings[weekday],
  //       [field]: dayjs(value).format("HH:mm:ss"),
  //     },
  //   }));
  // };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const HandleWebSettings = (row: any) => {
    setSelectedRowId(row?.id);
    setWebSettingsModal(true);
    ListWebSettings(row?.id);
  };

  const FailedModal = () => {
    setWebSettingsModal(false);
    setFailedModal(true);
  };

  const handleDeleteTimeSlot = (indexToRemove: any) => {
    const updatedTimeSlots = timeSlots.filter(
      (slot, index) => index !== indexToRemove
    );
    setTimeSlots(updatedTimeSlots);
  };

  const ManageWebSettingsData = async () => {
    try {
      setLoading(true);
      setFilterDisabled(true);
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
        ApiServices.manage_web_settings +
        `?user_code=${UserCodeData}&location_ids=${selectedLocations}&page=${controller.page}&field=${controller.field}&order=${controller.order}`
      );
      setFilterDisabled(false);
      if (response.status === true) {
        setmanageWebSettings(response?.web_settings?.data || []);
        setLoading(false);
        setRecordsCount(response?.web_settings?.total);
      }
    } catch (error) {
      setFilterDisabled(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ManageWebSettingsData();
  }, [selectedLocations])

  const ListWebSettings = async (itemId: any) => {
    try {
      const headers = {
        Authorization: `Bearer ${tokenData}`,
      };
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL + ApiServices.List_web_settings + itemId,
        headers
      );
      if (response.status === true) {
        dispatch(setWebList(response.web_settings));
        setLocationId(response.web_settings.location_id);

        setStatus(response.web_settings.status);
        setWebOptions(response.web_settings?.web_options);
        let periods = response?.web_settings?.business_hours?.periods;

        if (periods && periods?.length >= 1) {
          let days = periods?.map((elem: any) => {
            return daysOfWeek.filter(
              (item: any) => item?.value == elem?.day_of_week
            );
          });

          let onlyNames = days?.map((elem: any) => elem[0]?.name);

          setSelectedDays(onlyNames);
          if (onlyNames?.length >= 7) {
            setSelectAll(true);
          }

          let timeSlots = periods?.map((elem: any) => ({
            day_of_week: elem?.day_of_week,
            start_local_time: elem?.start_local_time,
            end_local_time: elem?.end_local_time,
          }));

          setTimeSlots([...timeSlots]);
        } else {
          setTimeSlots([{ day_of_week: "", start_local_time: "", end_local_time: "" }]);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const UpdatingWebSettingsTable = async () => {
    const Params = {
      // user_code: UserCodeData,
      // device_id: device_id,
      // menu_code: menu_code,
      // status: status === "ACTIVE" ? 1 : 0
    };
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.update_kiosk,
        Params
      );
      if (response.status === true) {
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else if (response.status === false) {
        setTimeout(() => {
          setFailedModal(true);
        }, 100);
      }
    } catch (error) {
      enqueueSnackbar("Incorrect Details", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("error catch", error);
    }
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? "desc"
          : controller.order === "asc"
            ? "desc"
            : "asc",
      page: 1,
    });

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleNameClick = (id: any) => {
    dispatch(setLocationSelectedId(id));
    navigate("/location");
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  const visibleRows = React.useMemo(() => {
    stableSort(
      filteredValues.length > 0 ? filteredValues : manageWebSettings,
      getComparator(order, orderBy)
    );
    return manageWebSettings;
  }, [order, orderBy, manageWebSettings, filteredValues]);

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: any
  ) => {
    const { checked } = event.target;

    if (WebList && WebList.web_options) {
      const updatedWebList = { ...WebList };
      updatedWebList.web_options = updatedWebList.web_options
        ? [...updatedWebList.web_options]
        : [];
      if (checked) {
        if (!updatedWebList.web_options.includes(option)) {
          updatedWebList.web_options.push(option);
        }
      } else {
        updatedWebList.web_options = updatedWebList.web_options.filter(
          (opt: string) => opt !== option
        );
      }
      console.log("checked: ", updatedWebList);

      dispatch(setWebList(updatedWebList));
    }

    if (webOptions?.includes(option)) {
      setWebOptions(webOptions?.filter((elem: any) => elem == option));
    } else {
      setWebOptions([...webOptions, option]);
    }
  };

  const handleSaveDetails = async (locationid: any) => {
    if (!locationid) {
      console.error("locationid or deviceid is missing");
      return;
    }

    setSaving(true);

    let days: any = [];
    selectedDays?.map((elem: any) => {
      let day = daysOfWeek?.filter((item) => item?.name == elem);
      days.push(day[0]);
    });

    let formatedData = days.map((elem: any, index: any) => {
      return {
        day_of_week: elem?.value,
        start_local_time: timeSlots[index]?.start_local_time,
        end_local_time: timeSlots[index]?.end_local_time,
      };
    });

    const requestData = JSON.stringify({
      business_hours: { periods: formatedData },
      web_options: webOptions,
      status: status,
      name: WebList?.name,
    });
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.update_Miniosk + selectedRowId,
        requestData
      );
      if (response.status === true) {
        enqueueSnackbar("Location settings updated successfully.", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
        setWebSettingsModal(false)
        setLoading(false)
        ManageWebSettingsData()
      } else {
        enqueueSnackbar(response.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
      }
    } catch (error) {
      console.error("Error saving details:", error);
      enqueueSnackbar("Error occurred while saving details", {
        variant: "error",
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    setController({
      ...controller,
      page: 1,
    });
  }, [filteredLocations.length]);

  useEffect(() => {
    // ManageWebSettingsData();
  }, [controller]);

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  const actionButton = (
    <button
      style={{
        height: "34px",
        width: "39.9px",
        borderRadius: "6px",
        border: 0,
        alignItems: "center",
        backgroundColor: "#8B5CFF1A",
        color: "#fff",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <img
        alt="Edit"
        src={Edit}
        style={{ height: "16.77px", width: "16.77px" }}
      />
    </button> // Prevent row click event
  );

  return (
    <Fragment>
      {/* {loading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink />
        </div>
      ) : ( */}
      <DataTable
        order={order}
        orderBy={orderBy}
        nPages={nPages}
        currentRecords={currentRecords}
        visibleRows={visibleRows}
        controller={controller}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setController={setController}
        setCurrentPage={setCurrentPage}
        headCells={headCells}
        loading={loading}
        actionElement={actionButton}
        onActionClick={HandleWebSettings}
      />
      {/* <Box className={classes.TableMain}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size={"medium"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={manageWebSettings.length}
              controller={controller}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : visibleRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body2">No Data Found</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((row: any, index: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell align="left" style={{ width: "145px" }}>
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#8B5CFF",
                            fontFamily: "Poppins",
                            width: "145px",
                          }}
                          onClick={() => handleNameClick(row?.location_id)}
                        >
                          {row?.location_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ width: "128px" }}>
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                            width: "142px",
                          }}
                        >
                          {row?.menu_count}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                            width: "92px",
                          }}
                        >
                          {row?.category_count}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                          }}
                        >
                          {row?.all_items}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                          }}
                        >
                          {row?.web_options?.length >= 1
                            ? row?.web_options.join(" ")
                            : "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 500,
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            color: row?.status === 1 ? "#18BC46" : "red",
                            width: "128px",
                          }}
                        >
                          {row?.status === 1 ? "ACTIVE" : "INACTIVE"}
                        </Typography>
                        <div
                          onClick={() => {
                            HandleWebSettings(row?.id);
                          }}
                          style={{
                            marginLeft: "24px",
                            backgroundColor: "#8B5CFF1A",
                            height: "34px",
                            width: "39.9px",
                            borderRadius: "6px",
                            border: 0,
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="Edit"
                            src={Edit}
                            style={{ height: "16.77px", width: "16.77px" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {nPages > 1 && (
          <Pagination
            page={controller.page}
            count={nPages}
            color="primary"
            size="small"
            onChange={handlePaginationChange}
            className={classes.paginationPrevNextBtn}
          />
        )}
      </Box> */}
      {/* )} */}
      <Modal
        open={webSettingsModal}
        onClose={() => {
          setWebSettingsModal(false);
          setSelectedDays([]);
          setTimeSlots([]);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {/* {WebList.filter((item: any) => item?.id === selectedRowId).map((item: any, index: any) => { */}
          {/* return ( */}
          <Box
            sx={{
              bottom: "-100%",
              transition: "bottom 0.3s ease-in-out",
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "47.73vw",
              height: "80.15vh",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              padding: "16px",
              borderWidth: 0,
              // overflowY: "auto",
            }}
          >
            <style>
              {`::-webkit-scrollbar {width: 6px;} ::-webkit-scrollbar-track {   display: none; } ::-webkit-scrollbar-thumb {   background-color: transparent; }`}
            </style>
            <div className="import-title-container">
              <h3 className="import-title">Edit Details</h3>
              <div
                style={{
                  backgroundColor: "#F34A4A29",
                  height: "27.06px",
                  width: "27.06px",
                  borderRadius: "27.91px",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <img
                  onClick={() => setWebSettingsModal(false)}
                  src={Cross}
                  alt="Cross"
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                />
              </div>
            </div>
            <div
              className="menu-details"
              style={{ maxHeight: "63vh", overflowY: "auto" }}
            >
              <h3 className="menu-heading">Web URL</h3>
              <h3
                style={{
                  fontWeight: 500,
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  margin: 0,
                  color: "#8B5CFF",
                  marginTop: "8px",
                }}
              >
                {WebList?.web_url || "N/A"}
              </h3>
              {/* <h3 className="menu-heading" style={{ marginTop: "16px" }}>
                Select Days
              </h3>
              <InputLabel id="days-label">Select Days</InputLabel>
              <Select
                labelId="days-label"
                id="days-select"
                multiple
                value={selectedDays}
                onChange={handleDaySelect}
                renderValue={(selected) => (selected as string[]).join(", ")}
                style={{
                  marginTop: "8px",
                  width: "44.94vw",
                  height: "7.20vh",
                  borderRadius: "16px",
                  borderWidth: "1px",
                  borderColor: "#201C1C3D",
                  padding: "10px 16px 10px 16px",
                  outline: "none",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              >
                <MenuItem key="select-all">
                  <Checkbox checked={selectAll} onChange={handleSelectAll} />
                  <ListItemText primary="Select All" />
                </MenuItem>
                {daysOfWeek.map((day) => {
                  return (
                    <MenuItem key={day.name} value={day.name}>
                      <Checkbox checked={selectedDays?.includes(day.name)} />
                      <ListItemText primary={day.name} />
                    </MenuItem>
                  );
                })}
              </Select> */}
              {/* <input placeholder='Menu Name' value={"Heading"} style={{ marginTop: "8px", width: "44.94vw", height: "7.20vh", borderRadius: "16px", borderWidth: "1px", borderColor: "#201C1C3D", padding: "10px 16px 10px 16px", outline: "none", fontWeight: "400", fontSize: "14px", fontFamily: "Poppins" }} /> */}
              <h3 className="menu-heading" style={{ marginTop: "16px" }}>
                Select Time Slots
              </h3>

              <div
                className="timeslots-input-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  // alignItems: "center",
                }}
              >
                {timeSlots?.map((slot, index) => (
                  <div
                    key={index}
                    className="timeslot-input-row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems:"center",
                        justifyContent: "space-between",
                        gap: "8px",
                        width: "100%",
                      }}
                    >
                      <h6 style={{width:"10%"}}>{slot?.day_of_week}</h6>
                      {/* <input
                      placeholder="startTime"
                      // value={slot.startTime}
                      value={slot?.startTime || "00:00:00 "}
                      onChange={(e) =>
                        handleTimeSlotChange(index, "startTime", e.target.value)
                      }
                      style={{
                        marginTop: "8px",
                        width: "19.98vw",
                        height: "7.20vh",
                        borderRadius: "16px",
                        borderWidth: "1px",
                        borderColor: "#201C1C3D",
                        padding: "10px 16px 10px 16px",
                        outline: "none",
                        fontWeight: "400",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                      }}
                    />
                    <input
                      placeholder="endTime"
                      // value={slot.endTime}
                      value={slot?.endTime || "00:00:00 "}
                      onChange={(e) =>
                        handleTimeSlotChange(index, "endTime", e.target.value)
                      }
                      style={{
                        marginTop: "8px",
                        width: "19.98vw",
                        height: "7.20vh",
                        borderRadius: "16px",
                        borderWidth: "1px",
                        borderColor: "#201C1C3D",
                        padding: "10px 16px 10px 16px",
                        outline: "none",
                        fontWeight: "400",
                        fontSize: "14px",
                        fontFamily: "Poppins",
                        marginLeft: "16px",
                      }}
                    /> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="Start Time"
                            onChange={(val) =>
                              handleTimeSlotChange(index, "start_local_time", val)
                            }
                            value={
                              slot?.start_local_time
                                ? dayjs(slot?.start_local_time, "HH:mm:ss")
                                : null
                            }
                            maxTime={
                              slot?.end_local_time
                                ? dayjs(slot?.end_local_time, "HH:mm:ss")
                                : null
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["TimePicker"]}>
                          <TimePicker
                            label="End Time"
                            onChange={(val) =>
                              handleTimeSlotChange(index, "end_local_time", val)
                            }
                            value={
                              slot?.end_local_time
                                ? dayjs(slot?.end_local_time, "HH:mm:ss")
                                : null
                            }
                            minTime={
                              slot?.start_local_time
                                ? dayjs(slot?.start_local_time, "HH:mm:ss")
                                : null
                            }
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    {index === 0 ? (
                      <div
                        className="timeslot-input-row"
                        style={{
                          height: "28px",
                          width: "28px",
                          borderRadius: "6px",
                          backgroundColor: "#8B5CFF",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          marginLeft: "20px",
                        }}
                      >
                        <img
                          onClick={handleAddTimeSlot}
                          alt="plus"
                          src={Plus}
                          style={{
                            height: "11.67px",
                            width: "11.67px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="timeslot-input-row"
                        style={{
                          height: "28px",
                          width: "28px",
                          borderRadius: "6px",
                          backgroundColor: "#F34A4A33",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          marginLeft: "20px",
                        }}
                      >
                        <img
                          onClick={() => handleDeleteTimeSlot(index)}
                          alt="delete"
                          src={Delete}
                          style={{
                            height: "19.67px",
                            width: "19.67px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <h3 className="menu-heading" style={{ marginTop: "16px" }}>
                Menu Name
              </h3>
              <input
                placeholder="Menu Name"
                value={WebList.name}
                onChange={(e) => setMenuName(e.target.value)}
                style={{
                  marginTop: "8px",
                  width: "44.94vw",
                  height: "7.20vh",
                  borderRadius: "16px",
                  borderWidth: "1px",
                  borderColor: "#201C1C3D",
                  padding: "10px 16px 10px 16px",
                  outline: "none",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              />
              <p className="input-sub-text">
                Menu Cant change when location is active
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3 className="menu-heading" style={{ marginTop: "16px" }}>
                  Available For Pickup
                </h3>
                <Switch
                  checked={WebList?.web_options?.includes("pickup")}
                  onChange={(e) => handleSwitchChange(e, "pickup")}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3 className="menu-heading" style={{ marginTop: "16px" }}>
                  Available For Delivery
                </h3>
                <Switch
                  checked={WebList?.web_options?.includes("delivery")}
                  onChange={(e) => handleSwitchChange(e, "delivery")}
                />
              </div>
              <h3 className="menu-heading" style={{ marginTop: "16px" }}>
                Status
              </h3>
              <Select
                // disabled={WebList?.selected === 0}
                placeholder="Menu Name"
                value={status === 1 ? "ACTIVE" : "INACTIVE"}
                onChange={(e) => setStatus(e.target.value === "ACTIVE" ? 1 : 0)}
                style={{
                  marginTop: "8px",
                  width: "44.94vw",
                  height: "7.20vh",
                  borderRadius: "16px",
                  borderWidth: "1px",
                  borderColor: "#201C1C3D",
                  padding: "10px 16px 10px 16px",
                  outline: "none",
                  fontWeight: "400",
                  fontSize: "14px",
                  fontFamily: "Poppins",
                }}
              >
                <MenuItem value="ACTIVE">Active</MenuItem>
                <MenuItem value="INACTIVE">Inactive</MenuItem>
              </Select>
            </div>
            <button
              onClick={() => handleSaveDetails(locationid)}
              className="common-button-blue"
              disabled={saving}
            >
              {saving ? <CircularProgress size={24} sx={{ color: '#8B5CFF' }} /> : "Save"}
            </button>
            <button
              onClick={() => setWebSettingsModal(false)}
              className="common-button-cancel"
            >
              Cancel
            </button>
          </Box>
          {/* ) */}
          {/* })} */}
        </>
      </Modal>

      <Modal
        open={failedModal}
        onClose={() => setFailedModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "44.8vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="success-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h2 className="success-header">Uh-oh!!</h2>
              <img
                alt="Emoji"
                src={Emoji}
                style={{ width: "30px", height: "30px", marginLeft: "14px" }}
              />
            </div>
            <p className="success-desc">
              You cant Changes menu when location is active
            </p>
            <button onClick={() => setFailedModal(false)} className="done-btn">
              Done
            </button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default WebSettingsTable;
