import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
  LinearProgress,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Edit from "../assets/images/editmenu.png";
import Modal from "@mui/material/Modal";
import Cross from "../assets/images/Cross.png";
import Emoji from "../assets/images/emoji.png";
import ApiServices from "../helpers/ApiServices";
import HelperModule from "../helpers/HelperModule";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { setMenuListDetails } from "../store/reducers/MenuListReducer";
import { useSelector } from "react-redux";
import DataTable from "./DataTable";

type Order = "asc" | "desc";

const headCells: any = [
  {
    id: "device_id",
    disablePadding: false,
    label: "Device Id",
    sortable: false,
  },
  {
    id: "device_name",
    disablePadding: false,
    label: "Device Name",
    sortable: true,
  },
  {
    id: "menu_name",
    disablePadding: false,
    label: "Menu Name",
    sortable: true,
  },
  {
    id: "location_name",
    disablePadding: false,
    label: "Location",
    sortable: true,
  },
  {
    id: "total_orders",
    disablePadding: false,
    label: "Total Orders",
    sortable: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sortable: true,
  },
];

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string | any },
  b: { [key in Key]: number | string | any }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

let UserCodeData = localStorage.getItem("UserCode");

const KioskTable = ({ setFilterDisabled, selectedLocations }: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { filteredLocations } = useSelector(
    (store: any) => store.locationDetails
  );

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });

  const [recordsCount, setRecordsCount] = useState(0);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("device_id");
  const [editModal, setEditModal] = React.useState(false);
  const [failedModal, setFailedModal] = React.useState(false);
  const [kioskData, setKioskData]: any = useState([]);
  const [selectedRowData, setSelectedRowData]: any = useState(null);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [menuName, setMenuName] = useState("");
  const [status, setStatus]: any = useState();
  const [device_id, setDevice_id]: any = useState("");
  const [menu_code, setMenu_code]: any = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  // const currentRecords = kioskData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [filteredValues, setFilteredValues] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const ManageKioskData = async () => {
    try {
      setLoading(true);
      setFilterDisabled(true);
      let query =
        ApiServices.base_URL +
        ApiServices.manage_kiosk +
        `?user_code=${UserCodeData}&location_ids=${selectedLocations}&page=${controller.page}&field=${controller.field}&order=${controller.order}`;

      const response = await HelperModule().getMethod(query);
      setFilterDisabled(false);

      if (response.status === true) {
        setKioskData(response?.kiosks?.data);
        setRecordsCount(response?.kiosks?.total);
        setLoading(false);
      }
    } catch (error) {
      setFilterDisabled(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // console.log("first111",kioskData)
    setCurrentRecords(kioskData);
  }, [kioskData]);

  // console.log(currentRecords)

  useEffect(() => {
    ManageKioskData();
  }, [selectedLocations]);

  const HandleEdit = (rowData: any) => {
    setEditModal(true);
    setSelectedRowData(rowData);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? "desc"
          : controller.order === "asc"
          ? "desc"
          : "asc",
      page: 1,
    });

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const Popup = () => {
    setFailedModal(true);
    setEditModal(false);
  };

  const MenusListData = async () => {
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.menu_list + UserCodeData
      );
      if (response.status === true) {
        dispatch(setMenuListDetails(response.menu));
        console.log(response.menu, "khgygopyfo;ytytfghlhkh");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateKiosk = async () => {
    const Params = {
      // user_code: UserCodeData,
      device_name: deviceName,
      device_id: device_id,
      menu_code: menu_code,
      status: status === "ACTIVE" ? 1 : 0,
      menu_name: menuName,
      // status: status === "ACTIVE" ? Popup() : setFailedModal(false)
    };

    try {
      // if (status === "ACTIVE") {
      //   Popup();
      //   return;
      // }
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.update_kiosk,
        Params
      );
      console.log(response, "response");

      if (response.status === true) {
        // window.location.reload()
        setEditModal(false);
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        ManageKioskData();
      } else if (response.status === false) {
        setEditModal(false);
        setTimeout(() => {
          setFailedModal(true);
        }, 100);
        // enqueueSnackbar(response.errors.email[0], { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
      }
    } catch (error) {
      enqueueSnackbar("Incorrect Details", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
      console.log("error catch", error);
    }
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  const visibleRows = React.useMemo(() => {
    stableSort(
      filteredValues.length > 0 ? filteredValues : kioskData,
      getComparator(order, orderBy)
    );
    return kioskData;
  }, [order, orderBy, kioskData, filteredValues]);

  const getFormattedDateTime = (dateTime: string) => {
    const dateObject = new Date(dateTime);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const formattedTime = dateObject.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  useEffect(() => {
    // Update state variables when selectedRowData changes
    if (selectedRowData) {
      setDeviceName(selectedRowData.device_name || "");
      setMenuName(selectedRowData.menu_name || "");
      setStatus(selectedRowData.status || "");
      setDevice_id(selectedRowData.device_id || "");
      setMenu_code(selectedRowData.menu_code || "");
    }
  }, [selectedRowData]);

  useEffect(() => {
    MenusListData();
  }, []);

  useEffect(() => {
    setController({
      ...controller,
      page: 1,
    });
  }, [filteredLocations.length]);

  useEffect(() => {
    ManageKioskData();
  }, [controller]);

  const actionButton = (
    <button
      style={{
        height: "34px",
        width: "39.9px",
        borderRadius: "6px",
        border: 0,
        alignItems: "center",
        backgroundColor: "#8B5CFF1A",
        color: "#fff",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <img
        alt="Edit"
        src={Edit}
        style={{ height: "16.77px", width: "16.77px" }}
      />
    </button> // Prevent row click event
  );

  return (
    <Fragment>
      <DataTable
        order={order}
        orderBy={orderBy}
        nPages={nPages}
        currentRecords={currentRecords}
        visibleRows={visibleRows}
        controller={controller}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setController={setController}
        setCurrentPage={setCurrentPage}
        headCells={headCells}
        loading={loading}
        actionElement={actionButton}
        onActionClick={HandleEdit}
      />

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            // bottom: "-100%",
            // transition: "bottom 0.3s ease-in-out",
            // position: "absolute" as "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            width: "40%",
            // height: "55.15vh",
            overflow: "scroll",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
            borderWidth: 0,
          }}
        >
          <div className="import-title-container">
            <h3 className="import-title">Edit Details</h3>
            <div
              style={{
                backgroundColor: "#F34A4A29",
                height: "27.06px",
                width: "27.06px",
                borderRadius: "27.91px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                onClick={() => setEditModal(false)}
                src={Cross}
                alt="Cross"
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">Device Name</h3>
            <input
              placeholder="Device Name"
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              // readOnly={true}
              style={{
                marginTop: "8px",
                width: "34.94vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
            <h3 className="menu-heading" style={{ marginTop: "16px" }}>
              Menu Name
            </h3>

            <input
              placeholder="Menu Name"
              value={menuName}
              readOnly={true}
              onChange={(e) => setMenuName(e.target.value)}
              style={{
                marginTop: "8px",
                width: "34.94vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            />
            <p className="input-sub-text">
              Menu Cant change when location is active
            </p>
            <h3 className="menu-heading" style={{ marginTop: "16px" }}>
              Status
            </h3>
            <Select
              placeholder="Menu Name"
              value={
                status === 1
                  ? "ACTIVE"
                  : status === "ACTIVE"
                  ? "ACTIVE"
                  : "INACTIVE"
              }
              onChange={(e) => setStatus(e.target.value)}
              style={{
                marginTop: "8px",
                width: "34.94vw",
                height: "7.20vh",
                borderRadius: "16px",
                borderWidth: "1px",
                borderColor: "#201C1C3D",
                padding: "10px 16px 10px 16px",
                outline: "none",
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Poppins",
              }}
            >
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
          </div>
          <div
            style={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button onClick={UpdateKiosk} className="common-button-blue">
              Save
            </button>
            <button
              onClick={() => setEditModal(false)}
              className="common-button-cancel"
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={failedModal}
        onClose={() => setFailedModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "749.5px",
            height: "200px",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="success-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h2 className="success-header">Uh-oh!!</h2>
              <img
                alt="Emoji"
                src={Emoji}
                style={{ width: "30px", height: "30px", marginLeft: "14px" }}
              />
            </div>
            <p className="success-desc">
              You cant Changes menu when location is active
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <button
              onClick={() => setFailedModal(false)}
              style={{
                cursor: "pointer",
                color: "white",
                backgroundColor: "#8B5CFF",
                textAlign: "center",
                fontSize: "12px",
                height: "33px",
                width: "150px",
                borderRadius: "8px",
                fontWeight: "400",
                fontFamily: "Poppins",
                margin: 0,
              }}
            >
              Done
            </button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default KioskTable;
