import React, { useEffect, useState } from 'react'
import BGIMAGE from '../../assets/images/background.jpg'
import { useNavigate } from 'react-router-dom';
import LOGO from '../../assets/images/logo_main_img.png'
import './HelpUsToGrow.css'
import ApiServices from '../../helpers/ApiServices';
import { enqueueSnackbar } from 'notistack';
import HelperModule from '../../helpers/HelperModule';
let userCodeData: any
function HelpUsToGrow() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');


  useEffect(() => {
    const UserCode = localStorage.getItem('UserCode')
    userCodeData = UserCode
  }, [])

  const HelpToGrow = async () => {
    const Params = {
      user_code: userCodeData,
      referred_by_platform: selectedOption,
    }
    console.log("Params", Params)
    try {
      const response = await HelperModule().postMethod(ApiServices.base_URL + ApiServices.help_us, Params);
      console.log(response)
      if (response.status === true) {
        console.log(response)
        enqueueSnackbar(response.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' } });
        navigate('/trails');
      }
      // dispatch(setIsLogin(true))
    } catch (error) {
      console.error('API Error:', error);
    }
  }

  return (
    <div className='container-login text-start'>
      <div className='main-container-login' style={{ width: "40%" }}>
        <h2 className='miniosk-login'>Helps Us To Grow</h2>
        <p className='miniosk-login-desc'>How Did You Hear About Us ?? </p>
        <div>
        </div>
        <div className="radioButton_maindiv">
          <label className='labelForradio'>
            <input type="radio" name="radio" value="Google" onChange={() => setSelectedOption('google')} />
            <span className='textForRadio-btn'>Google</span>
          </label>
          <label className='labelForradio'>
            <input type="radio" name="radio" value="Facebook" onChange={() => setSelectedOption('facebook')} />
            <span className='textForRadio-btn'>Facebook</span>
          </label>
          <label className='labelForradio'>
            <input type="radio" name="radio" value="Instagram" onChange={() => setSelectedOption('instagram')} />
            <span className='textForRadio-btn'>Instagram</span>
          </label>
          <label className='labelForradio'>
            <input type="radio" name="radio" value="by_friend" onChange={() => setSelectedOption('by_friend')} />
            <span className='textForRadio-btn'>By Friend</span>
          </label>
          <label className='labelForradio'>
            <input type="radio" name="radio" value="Ads" onChange={() => setSelectedOption('ads')} />
            <span className='textForRadio-btn'>Ads</span>
          </label>
          <label className='labelForradio'>
            <input type="radio" name="radio" value="Others" onChange={() => setSelectedOption('other')} />
            <span className='textForRadio-btn'>Others</span>
          </label>
        </div>
        <button className='continue-btn' onClick={HelpToGrow}>Continue</button>
        <div className="kiosk_imgDiv" >
          <img src={LOGO} alt='logo' style={{ width: '150px', }} />
        </div>
      </div>
      <div style={{ height: '100vh', overflow: 'hidden', width: '60%' }}>
        <img src={BGIMAGE} alt='bg' style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    </div>
  )
}

export default HelpUsToGrow