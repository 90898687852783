import React from 'react'
import BGIMAGE from '../../assets/images/background.jpg'
import { useNavigate } from 'react-router-dom';
import LOGO from '../../assets/images/logo_main_img.png'
import { useAppDispatch } from '../../Hooks';
import { setIsLogin, setPosConnect } from '../../store/reducers/AuthReducer';

function Trails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleContinueClick = async () => {
    const isAuthenticated = await localStorage.getItem('Token');
    dispatch(setIsLogin(true))
    console.log("isAuthenticated", isAuthenticated)
    window.location.href = "/linkaccount"
    if (isAuthenticated) {
      dispatch(setIsLogin(true))
      console.log("Authenticated")
      dispatch(setPosConnect(0))
      // await localStorage.setItem("PosAccount", "0")
      let login = await localStorage.setItem("IsLogin", "true")
      console.log(login);
      window.location.href = "/linkaccount"
      // window.location.href   = "/linkaccount"
    }
    else {
      dispatch(setIsLogin(true))
      console.log("UnAuthenticated")

    }
  }

  return (
    <div className='container-login'>
      <div className='container-login-trail' style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', width: '40%', paddingLeft: '25px', paddingRight: '25px', }}>
        <h2 className='miniosk-login' style={{ textAlign: 'center', fontFamily: 'Poppins', fontWeight: '600', fontSize: '24px' }}>Your 7 days free trail has been activated</h2>
        <button className='continue-button' onClick={handleContinueClick}>Continue</button>
        <div className='kiosk_imgDiv' style={{ marginLeft: '40px' }}>
          <img src={LOGO} alt='logo' className='miniosk-img' style={{ width: '150px', }} />
        </div>
      </div>
      <div style={{ height: '100vh', overflow: 'hidden', width: '60%' }}>
        <img src={BGIMAGE} alt='bg' style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
    </div>
  )
}

export default Trails