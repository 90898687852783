import React, { useEffect, useState } from 'react'
import BGIMAGE from '../../assets/images/background.jpg'
import { useLocation, useNavigate } from 'react-router-dom';
import LOGO from '../../assets/images/logo_main_img.png'
import './Otp.css'
import OtpInput from 'react-otp-input';
import HelperModule from '../../helpers/HelperModule';
import ApiServices from '../../helpers/ApiServices';
import { useSnackbar } from 'notistack';
import { useAppDispatch, useAppSelector } from "../../Hooks";
import { seteMailSelected } from "../../../src/store/reducers/AuthReducer";
import { Button } from '@mui/base';
import CircularProgress from '@mui/material/CircularProgress';
import Edit from '../../assets/images/editEmail.png'


function Otp() {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [userCode, setUserCode]: any = useState('');
    const [email, setEmail]: any = useState();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { emailSelected, userDetails } = useAppSelector((state) => state.Auth);
    const [start, setStart] = useState(true);
    const storedTimer = sessionStorage.getItem('timer');
    const [timer, setTimer] = useState(() => {
        const storedTimer = sessionStorage.getItem('timer');
        return storedTimer ? Math.max(0, parseInt(storedTimer)) : 60;
    });

    useEffect(() => {
        const Email = localStorage.getItem('email')
        console.log("Email", Email)
        const ReferralEmail = localStorage.getItem('emailCreate')
        console.log(ReferralEmail, "ReferralEmail")
        setEmail(userDetails.email || ReferralEmail)
        const storedTimer = sessionStorage.getItem('timer');
        if (storedTimer) {
            setTimer(parseInt(storedTimer));
        }
    }, [])

    useEffect(() => {
        const UserCode = localStorage.getItem("UserCode")
        setUserCode(UserCode)
    }, []);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (timer > 0) {
                setTimer((prevTimer) => {
                    sessionStorage.setItem('timer', String(prevTimer - 1));
                    return prevTimer - 1;
                });
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [timer]);

    const VerifyOtp = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const Params = {
            "user_code": userCode,
            "email_otp": otp
        }
        console.log("ParamsPhne", Params)
        try {
            const response = await HelperModule().postMethod(ApiServices.base_URL + ApiServices.verify_user_otp, Params);
            if (response.status === true) {

                console.log(response)

                enqueueSnackbar(response.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
                navigate('/createPassword');
            } else if (response.status === false) {
                enqueueSnackbar(response.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
            }
            else {
                enqueueSnackbar("Invalid OTP", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
            }
        }
        catch (error) {
            enqueueSnackbar("Invalid OTP", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
            console.log("error catch", error)
        }
        finally {
            setLoading(false);
        }
    }

    const ResendOtp = async (e: any) => {
        const Params = {
            "email": email
        };
        try {
            const response = await HelperModule().postMethod(ApiServices.base_URL + ApiServices.resend_otp, Params);
            if (response.status === true) {
                setTimer(60);
                sessionStorage.setItem('timer', '60');
                enqueueSnackbar(response.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
            } else {
                enqueueSnackbar(response.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
            }
        } catch (error) {
            enqueueSnackbar("Invalid Email", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' }, });
        }
    };


    return (
        <div className='container-login text-start'>
            <div className='main-container-otp' style={{ width: '35%', }}>
                <div className="basic-otp-container">
                    <h2 className='miniosk-login'>Security Check!</h2>
                    <p className='miniosk-login-desc'>Verification code sent! Check</p>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', }}>
                        <p className='miniosk-login-verifivation'>{email}</p>
                        <img
                            onClick={() => {
                                navigate('/createprofile',{state: { email:email }});
                            }}
                            src={Edit}
                            alt="edit"
                            style={{ height: "16px", width: "16px", marginBottom: '10px', cursor: 'pointer' }}
                        />                    </div>
                    <p className='email-div'>{emailSelected}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', justifyContent: 'space-between', width: '330px' }}>
                        <h3 className='enter-miniosk' style={{paddingBottom: '10px'}}>Enter code</h3>
                        <h3 style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#8B5CFF', fontSize: '18px', paddingTop: "30px", }}>{timer > 0 && timer + 's'}</h3>
                    </div>
                </div>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    inputType='tel'
                    skipDefaultStyles={false}
                    renderSeparator={<span style={{ padding: "10px" }}> </span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{ width: '39px', height: "39px", borderRadius: '8px', border: '1px solid #ADADAD', marginTop: '-4px', outline: 'none', fontFamily: 'Poppins', }}
                />
                {timer > 0 ? null : (
                    <div>
                        <h3 onClick={(e) => ResendOtp(e)} style={{ fontFamily: 'Poppins', fontWeight: '500', color: '#8B5CFF', cursor: 'pointer', fontSize: '18px', marginTop: '10px', marginLeft: '265px', textDecoration: 'underline' }}>Resend</h3>
                    </div>
                )}
                <div className="continue-btn-otp-container">
                    {/* <button type='button' onClick={(e) => VerifyOtp(e)} className='continue-btn'>Continue</button> */}
                    <Button disabled={otp.length < 6} type='button' onClick={(e) => VerifyOtp(e)} className="continue-button" style={{width: '337.92px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                        {loading && <CircularProgress style={{ marginRight: '20px' }}  sx={{color: '#8B5CFF'}} size={25} />}
                        Continue
                    </Button>
                </div>
                <div className="kiosk_imgDiv" >
                    <img src={LOGO} alt='logo' style={{ width: '150px', }} />
                </div>
            </div>
            <div style={{ height: '100vh', overflow: 'hidden', }}>
                <img src={BGIMAGE} alt='bg' style={{ height: '100%', objectFit: 'cover' }} />
            </div>
        </div >
    )
}

export default Otp