import React, { Fragment } from 'react';
import Login from '../screens/Login/Login';
import { Routes, Route, Navigate } from "react-router-dom";
import Password from '../screens/Password/Password';
import CreateProfile from '../screens/Register/CreateProfile';
import Otp from '../screens/Otp/Otp';
import CreatePassword from '../screens/CreatePassword/CreatePassword';
import HelpUsToGrow from '../screens/HelpUsToGrow/HelpUsToGrow';
import Trails from '../screens/Trails/Trails';
import ForgetPassword from '../screens/Password/ForgetPassword';
import PasswordSent from '../screens/Password/PasswordSent';
import ConfirmPassword from '../screens/Password/ConfirmPassword';
import PasswordSuccessful from '../screens/Password/PasswordSuccessful';

const AuthRoutes = () => {
    return (
        <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/password' element={<Password />} />
            <Route path='/createprofile/:id/:mail' element={<CreateProfile />} />
            <Route path='/createprofile/' element={<CreateProfile />} />
            {/* <Route path='/createprofile/:id|/createprofile/' element={<CreateProfile />} /> */}
            <Route path='/createPassword' element={<CreatePassword />} />
            <Route path="otp" element={<Otp />} />
            <Route path='/trails' element={<Trails />} />
            <Route path='/forgetpassword' element={<ForgetPassword />} />
            <Route path='/passwordsent' element={<PasswordSent />} />
            <Route path='/reset/:token' element={<ConfirmPassword />} />
            <Route path='/passwordsuccessful' element={<PasswordSuccessful />} />
            <Route path='/helpUsToGrow' element={<HelpUsToGrow />} />
            <Route path="/" element={<Navigate to={"/login"} replace={true} />} />
            <Route path="*" element={<Navigate to={"/login"} replace={true} />} />
        </Routes>
    )
}

export default AuthRoutes