import { Fragment, useState, ChangeEvent, useEffect } from 'react'
import { useTheme, Typography, Grid, Box, Popover, Checkbox, TextField, IconButton, } from '@mui/material';
import { useStyles } from './UseStyles';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useDispatch, useSelector } from 'react-redux';
import { setLocationsList } from '../store/reducers/LocationsReducer';
import axios from 'axios';
import ApiServices from '../helpers/ApiServices';
import { ClearIcon } from '@mui/x-date-pickers/icons';



let UserCodeData: any
const DropdoenFilter = ({ onLocationSelect }: any) => {
  const { LocationsList } = useSelector((state: any) => state.locationDetails);
  console.log(LocationsList, "LocationsList")
  const dispatch = useDispatch();
  const classes = useStyles();
  const { palette }: any = useTheme();
  const [onLocation, setLocation] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [tokenData, setToken] = useState('')
  const [locationSearch, setLocationSearch] = useState("");
  const [selectedLocationNames, setSelectedLocationNames] = useState<string[]>([]);


  // const generatePlaceholderText = () => {
  //   if (selected.length === 0) {
  //     return 'Select Location';
  //   } else if (selected.length === LocationsList.length) {
  //     return LocationsList.map((location: any) => location.name).join(', ');
  //   } else if (selected.length === 1) {
  //     return selected[0];
  //   } else {
  //     return `${selected.length} Locations Selected`;
  //   }
  // };

  const generatePlaceholderText = () => {
    if (selected.length === 0) {
      return 'Select Location';
    } else if (selected.length === LocationsList.length) {
      return LocationsList.map((location: any) => location.name).join(', ');
    } else if (selected.length === 1) {
      const selectedLocation = LocationsList.find((location: any) => location.location_id === selected[0]);
      return selectedLocation ? selectedLocation.name : '';
    } else {
      const selectedLocationNames = selected.map((id: string) => {
        const location = LocationsList.find((location: any) => location.location_id === id);
        return location ? location.name : '';
      });
      return selectedLocationNames.join(', ');
    }
  };





  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token")
    setToken(TokenData)
    console.log("TokenData", TokenData)
  }, []);

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode")
    UserCodeData = UserCode
  }, [])


  const handleLocation = (event: any) => {
    setLocation(event.currentTarget);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (selected.length === LocationsList.length) {
      setSelected([]);
      setSelectedLocationNames([]);
      onLocationSelect([]);
    } else {
      const allLocationNames = LocationsList.map((location: any) => location.location_id);
      setSelected(allLocationNames);
      setSelectedLocationNames(allLocationNames);
      onLocationSelect(allLocationNames);
    }
  };



  const handleClick = (name: any) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0 && selected.length === 1) {
      // Only one location selected, do not select all
      newSelected = [];
    } else if (selectedIndex === 0 && selected.length > 1) {
      // Multiple locations selected, do not change "Select All" checkbox
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    onLocationSelect(newSelected);
  };



  const isSelected = (id: any) => selected.indexOf(id) !== -1;




  const Locations = () => {
    if (!tokenData) {
      console.error('Token is not available yet.');
      return;
    }
    const apiEndpoint = ApiServices.base_URL + ApiServices.vendor_locations + `?user_code=${UserCodeData}&type=all`;
    const token = tokenData;
    axios.get(apiEndpoint, {
      headers: {
        'Authorization': `Bearer ${token}`,
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
    })
      .then((response: any) => {
        dispatch(setLocationsList(response.data.data));
        console.log("locations Data", response.data.data);
        console.log("locationID =========___________==>")
      })
      .catch(error => {
        console.error('API Error:', error);
      });
  }

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token")
    setToken(TokenData)

  }, []);

  useEffect(() => {
    if (tokenData) {
      Locations();
    }
  }, [tokenData]);

  useEffect(() => {
    const initialLocationsCall = setTimeout(() => {
      Locations();
    }, 1000);
    return () => clearTimeout(initialLocationsCall);
  }, [tokenData]);

  const handleClearSearch = () => {
    setLocationSearch('');
  };

  const filterLocations = () => {
    return LocationsList.filter((location: any) =>
      location.name.toLowerCase().includes(locationSearch.toLowerCase())
    );
  };


  return (
    <Fragment>
      <Grid justifyContent={"space-between"}>
        <Grid onClick={(event: any) => { handleLocation(event) }} >
          <Box sx={Styles.dropmenu} >
            <Typography variant='subtitle2' sx={{ color: "black", fontWeight: 500, lineHeight: "16px", }}> {generatePlaceholderText()}</Typography>
            <KeyboardArrowDownRoundedIcon sx={{ width: '20px', height: "20px" }} />
          </Box>
        </Grid>
      </Grid>

      <Popover
        open={Boolean(onLocation)}
        anchorEl={onLocation}
        onClose={() => setLocation(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: "center" }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          sx: {
            width: `${onLocation?.clientWidth}px`,
            borderRadius: "8px"
          }
        }}
      >
        <Box sx={{ p: .8, width: '100%', height: "200px", overflow: "auto" }}>
          <TextField
            type="Text"
            placeholder='Search'
            value={locationSearch}
            onChange={(e) => setLocationSearch(e.target.value)}
            InputProps={{
              style: {
                borderRadius: "6px",
                backgroundColor: '#FFFFFF'
              },

              sx: {
                ".MuiOutlinedInput-notchedOutline": {
                  borderRadius: "6px",
                },
                input: {
                  borderRadius: "6px",
                  backgroundColor: '#FFFFFF',
                  padding: '8px 8px',
                }
              },
              endAdornment: (
                locationSearch && (
                  <IconButton edge="end" size="small" onClick={handleClearSearch}>
                    <ClearIcon />
                  </IconButton>
                )
              ),
            }}
            size="medium"
            fullWidth

          />

          <Box className={classes.DasboardFilterMain} sx={{ pt: .5 }}>
            {filterLocations().length > 0 ? (
              <>
                <Box className={classes.DasboardFilterItem}>
                  <Checkbox disableRipple
                    indeterminate={selected.length > 0 && selected.length < LocationsList.length}
                    checked={selected.length === LocationsList.length && LocationsList.length > 0}
                    onChange={handleSelectAllClick} />
                  <Typography variant='subtitle1' sx={{ color: palette?.common?.checkBox, fontWeight: 500 }} >Select All</Typography>
                </Box>

                {filterLocations().map((value: any, index: any) => {
                  const isItemSelected = isSelected(value?.location_id);
                  return (
                    <Box key={index} className={classes.DasboardFilterItem}>
                      <Checkbox
                        disableRipple
                        checked={isItemSelected}
                        onChange={() => handleClick(value?.location_id)}
                      />
                      <Typography variant='subtitle1' sx={{ color: palette?.common?.checkBox, fontWeight: 500 }} >{value?.name}</Typography>
                    </Box>
                  )
                })}
              </>
            ) : (
              <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Typography variant="subtitle1" sx={{ textAlign: "center",display: "flex", justifyContent: "center", alignItems: "center", color: "#8B5CFF", fontWeight: 'bold' }}>No data found</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Fragment >
  )

}

export default DropdoenFilter;

const Styles = {
  dropmenu: {
    border: `1px solid ${"#201C1C52"}`,
    display: "flex",
    padding: "10px 16px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "8px",
    borderRadius: "8px",
    cursor: 'pointer',
    marginRight: "10px",
    width: "20.97vw",
    height: "7.20vh"
  },
}