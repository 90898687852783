import React, { useEffect, useState } from "react";
import "./CatalogStyles.css";
import Info from "../../assets/images/info.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Cross from "../../assets/images/Cross.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import SuccessModal from "../../components/SuccessModal";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import {
  FormControl,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMenuListDetails } from "../../store/reducers/MenuListReducer";
import { useSnackbar } from "notistack";
import { setLocationsList } from "../../store/reducers/LocationsReducer";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Delete from "../../assets/images/delete.png";
import ConfirmationModal from "../../components/ConfirmationModal";
import moment from "moment";

let AuthTokenId: any;
let UserCodeData: any;
function Catalog() {
  const { Menu } = useSelector((state: any) => state.menuListDetails);
  const dispatch = useDispatch();
  const { LocationsList } = useSelector((state: any) => state.locationDetails);
  const { enqueueSnackbar } = useSnackbar();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [selectedOption, setSelectedOption] =
    React.useState("Select a location");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [locations, setLocations]: any = useState([]);
  const [userCode, setUserCode]: any = useState("");
  const [menutitle, setMenuTitle]: any = useState("");
  const [locationID, setLocationID]: any = useState("");
  const [tokenData, setToken] = useState("");
  const [createMenuList, setCreateMenuList]: any = useState([]);
  const [loading, setloading] = useState(false);
  const [menuIdData, setMenuIdData]: any = useState("");
  const [deleteMenuConfirmModal, setDeleteMenuConfirmModal]: any =
    React.useState(false);
  const [successModalBodyText, setSuccessModalBodyText]: any = useState("");
  const [successModal, setSuccessModal] = React.useState(false);
  // const [menuListItems, setMenuListItems] = useState([]);
  const navigate = useNavigate();

  const handleSuccessModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const UserCode = localStorage.getItem("UserCode");
    UserCodeData = UserCode;
    setUserCode(UserCode);
  }, []);

  const handleOpenMenu = () => setOpenMenu(true);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleChange = (e: any) => {
    const selectedLocationID = e.target.value;
    setSelectedOption(selectedLocationID);
    setLocationID(selectedLocationID);
  };

  useEffect(() => {
    const TokenData: any = localStorage.getItem("Token");
    setToken(TokenData);
  }, []);

  useEffect(() => {
    if (tokenData) {
      Locations();
    }
  }, [tokenData]);

  useEffect(() => {
    const initialLocationsCall = setTimeout(() => {
      Locations();
    }, 1000);
    return () => clearTimeout(initialLocationsCall);
  }, [tokenData]);

  const Locations = () => {
    if (!tokenData) {
      console.error("Token is not available yet.");
      return;
    }
    const apiEndpoint =
      ApiServices.base_URL +
      ApiServices.vendor_locations +
      `?user_code=${UserCodeData}`;
    const token = tokenData;
    axios
      .get(apiEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response: any) => {
        dispatch(setLocationsList(response.data.data));
        setLocations(response.locations);
      })
      .catch((error) => {
        console.error("API Error:", error);
      });
  };

  const ImportMenus = async () => {
    setOpenMenu(false);
    setloading(true);
    const apiEndpoint = ApiServices.base_URL + ApiServices.create_menu;
    const token = tokenData;
    const Params = {
      user_code: UserCodeData,
      menu_name: menutitle,
      location_id: locationID,
      menu_sorting: true,
      status: true,
      user_web_menu: true,
    };
    const headers: any = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    };
    try {
      const response = await axios.post(apiEndpoint, Params, headers);
      if (response.data.status === true) {
        setCreateMenuList(response.data.data);
        setIsModalOpen(true);
        setMenuTitle("");
        MenusListData();
        setloading(false);
      } else {
        enqueueSnackbar(response.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.error("Menu Creation Failed", error);
      enqueueSnackbar("Menu Creation Failed", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } finally {
      setloading(false);
    }
  };

  const MenusListData = async () => {
    // setloading(true);
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.menu_list + UserCodeData
      );
      if (response.status === true) {
        dispatch(setMenuListDetails(response.menu));
        setloading(false);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setloading(false);
    }
  };
  // }, [userCode, dispatch]);

  useEffect(() => {
    // if (userCode) {
    MenusListData();
    // }
  }, [userCode, dispatch]);

  const endDate = Menu?.updated_at;
  const dateObject = new Date(endDate);

  const getFormattedDateTime = (dateTime: string) => {
    const dateObject = new Date(dateTime);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const formattedTime = dateObject.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${formattedDate} ${formattedTime}`;
  };

  const handleDeleteMenu = (menu_code: any) => {
    setMenuIdData(menu_code);
    setDeleteMenuConfirmModal(true);
  };

  const DeleteMenu = async () => {
    const headers = {
      Authorization: `Bearer ${AuthTokenId}`,
    };
    try {
      const response = await HelperModule().getMethodWithHeaders(
        ApiServices.base_URL +
        ApiServices.delete_menu +
        UserCodeData +
        "/" +
        menuIdData,
        headers
      );
      if (response.status === true) {
        setDeleteMenuConfirmModal(false);
        setSuccessModalBodyText("Deleted Menu successfully.");
        setSuccessModal(true);
      } else {
        console.log("Error Response", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {loading ?
        <div className="Loader-container">
          <CircularProgress disableShrink sx={{ color: '#8B5CFF' }} />
        </div>
        :
        <div className="container">
          <h1 className="catalog-title-heading">Catalog</h1>
          <div className="sub-title-container">
            <p className="catalog-sub-title">
              Manage your Miniosk menus and make edits to the items available
              for sale.
            </p>
          </div>
          <div className="menu-container">
            <h2 className="menu-title">Menus</h2>
            <div className="menu-card-row" style={{ cursor: "pointer" }}>
              {Menu?.length > 0 &&
                Menu?.map((item: any) => (
                  <>
                    <div className="menu-card">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <h3 className="menu-sub-title">{item?.menu_name.toUpperCase()}</h3>
                        <div
                          onClick={() => handleDeleteMenu(item.menu_code)}
                          className="edit-menu-container"
                          style={{
                            backgroundColor: "#F34A4A21",
                            marginTop: "10px",
                            marginRight: "5px",
                          }}
                        >
                          <img
                            src={Delete}
                            alt="delete"
                            style={{
                              width: "15.74px",
                              height: "15.74px",
                              backgroundColor: "#8B5CFF0A",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          navigate(
                            `/menuDetails/${item?.menu_name}/${item?.menu_code}`,
                            {
                              state: {
                                location_id: item.location_id,
                                menu_QR_code_data: item.menu_qr_code_url,
                                location_name: item?.location_details?.name,
                                time: getFormattedDateTime(item?.updated_at),
                                menu_id_data: item?.id,
                                hot_selling_status: item?.hot_selling_status,
                              },
                            }
                          )
                        }
                      >
                        <p className="distance-title">
                          {item?.location_details?.name}
                        </p>
                        <p className="time-title">
                          Last Edited: {moment(item?.updated_at).format("DD-MMM-YYYY")}
                        </p>
                      </div>
                    </div>
                  </>
                ))}
            </div>

            <div className="info-container">
              <img
                src={Info}
                alt="info"
                style={{ height: "16px", width: "16px" }}
              />
              <p className="info-desc">
                Click on a menu to edit it, delete it, or synchronize it with
                changes in your square item library.
              </p>
            </div>
            <button
              onClick={handleOpenMenu}
              className="import-menu"
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#6d43e57D";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#8B5CFF";
              }}
            >
              Import a menu
            </button>
          </div>
        </div>
      }
      <Modal
        open={openMenu}
        onClose={handleCloseMenu}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            transition: "bottom 0.3s ease-in-out",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "47.73vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="import-title-container">
            <h3 className="import-title">Import A New Menu</h3>
            <img
              onClick={handleCloseMenu}
              src={Cross}
              alt="Cross"
              style={{
                width: "30px",
                height: "32px",
                backgroundColor: "#F34A4A29",
                borderRadius: "33px",
              }}
            />
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">New Menu Name</h3>
            <input
              placeholder="Menu Name"
              value={menutitle}
              onChange={(e) => setMenuTitle(e.target.value)}
              className="input-container"
              maxLength={15}
            />
            <p className="input-sub-text">
              The name of the menu that will show up on the dashboard (for
              internal use only!).
            </p>
          </div>
          <div className="menu-details">
            <h3 className="menu-heading">Select a location</h3>
            <FormControl sx={{ mt: 1, width: "44.94vw" }}>
              <Select
                value={selectedOption}
                onChange={handleChange}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="Select a location">Select a location</MenuItem>
                {LocationsList?.length > 0 &&
                  LocationsList?.map((item: any) => (
                    <MenuItem key={item.id} value={item.location_id}>
                      {item?.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <p className="input-sub-text">
              The name of the menu that will show up on the dashboard (for
              internal use only!).
            </p>
          </div>
          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Sort imported data"
            color="black"
            className="check-box"
          />
          <p className="checkbox-sub-text">
            Select this checkbox to sort all imported Square data alphabetically
          </p>
          <div className="import-menu-btn" style={{}}>
            <button
              onClick={ImportMenus}
              className="import-btn"
              style={{ transition: "background-color 0.3s" }}
              onMouseEnter={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#6d43e57D";
              }}
              onMouseLeave={(e) => {
                (e.target as HTMLButtonElement).style.backgroundColor =
                  "#8B5CFF";
              }}
            >
              Import
            </button>
            <button onClick={handleCloseMenu} className="cancel-btn">
              Cancel
            </button>
          </div>
        </Box>
      </Modal>

      <SuccessModal
        open={isModalOpen}
        onClose={handleSuccessModalClose}
        headerText="Successful!"
        bodyText="You imported a new menu successfully."
      />
      <SuccessModal
        open={successModal}
        onClose={() => {
          setSuccessModal(false);
          MenusListData();
        }}
        headerText="Successful!"
        bodyText={successModalBodyText}
      />
      <ConfirmationModal
        open={deleteMenuConfirmModal}
        onConfirm={DeleteMenu}
        onCancel={() => setDeleteMenuConfirmModal(false)}
        headerText="Are you sure?"
        bodyText="You want to Delete Menu"
      />
    </div>
  );
}
export default Catalog;
