import React, { Fragment, useMemo, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigation,
} from "react-router-dom";
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "rsuite/dist/rsuite-no-reset.min.css";
// import 'rsuite/dist/rsuite.min.css';
import SidebarNav from "./screens/Sidebar/Sidebar";
import { SnackbarProvider } from "notistack";
import AppRoutes from "./routes/AppRoutes";
import AuthRoutes from "./routes/AuthRoutes";
import { useAppDispatch, useAppSelector } from "./Hooks";
import { setIsLogin } from "./store/reducers/AuthReducer";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const dispatch = useAppDispatch();
  const { IsLogin } = useAppSelector((state) => state?.Auth);

  useEffect(() => {
    if (localStorage.getItem("IsLogin")) {
      dispatch(setIsLogin(true));
    } else {
      dispatch(setIsLogin(false));
    }
  }, [IsLogin]);

  if (IsLogin === null) {
    return <Fragment></Fragment>;
  }

  return (
    <>
      <GoogleOAuthProvider clientId="554215699089-8m7u2cdp249f81telld1h27b9in0kvo6.apps.googleusercontent.com">
        <SnackbarProvider maxSnack={3}>
          <Router>
            {IsLogin ? (
              <div className="app-container">
                <SidebarNav />
                <div className="main-content">
                  <AppRoutes />
                </div>
              </div>
            ) : (
              <div className="app-container">
                <div className="main-content">
                  <AuthRoutes />
                </div>
              </div>
            )}
          </Router>
        </SnackbarProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
