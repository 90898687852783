import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useAppDispatch } from "../../Hooks";
import BGIMAGE from "../../assets/images/background.jpg";
import LOGO from "../../assets/images/logo_main_img.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setPosConnect } from "../../store/reducers/AuthReducer";

let firstName: any;
let lastName: any;
let email: any;
let phone: any;
let setPasswordToken: any;
function CreatePassword() {
  const navigate = useNavigate();
  const [password, setPassword]: any = useState("");
  const [confirmPassword, setConfirmPassword]: any = useState("");
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [auth_token, setAuthToken] = useState("");

  const dispatch = useAppDispatch();

  useEffect(() => {
    sessionStorage.removeItem("timer");
  }, []);

  useEffect(() => {
    const Authorization: any = localStorage.getItem("Token");
    setAuthToken(Authorization);
    const set_password_token = localStorage.getItem("set_password_token");
    setPasswordToken = set_password_token;
    console.log("Authorization====>>>***", Authorization);
  }, []);

  useEffect(() => {
    const FirstName = localStorage.getItem("firstNameCreate");
    firstName = FirstName;
    const LastName = localStorage.getItem("lastNameCreate");
    lastName = LastName;
    const Email = localStorage.getItem("emailCreate");
    email = Email;
    const Phone = localStorage.getItem("phoneCreate");
    phone = Phone;
    console.log(firstName, lastName, email, phone);
  }, []);

  const validatePassword = (passwordTest: any) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+?])[A-Za-z\d!@#$%^&*()_+?]{6,15}$/;
    return regex.test(password) && password.length <= 15;
  };

  const RegisterSuccess = async () => {
    try {
      if (!auth_token) {
        console.error("Token is not available yet.");
        console.log(auth_token);
        return;
      }
      const token = auth_token;
      console.log("token--------@@@@@@@@@@@@@", token);
      if (!validatePassword(password) || password.length > 15) {
        enqueueSnackbar(
          "Password: 6-15 chars, 1 uppercase, 1 special chars, 1 number.",
          {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            style: { fontFamily: "Poppins", fontWeight: "500" },
          }
        );
        return;
      }
      if (password !== confirmPassword || password === "") {
        enqueueSnackbar("Password didn't match", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }

      const url = `https://api.miniosk.com/api/resetPassword?password=${password}&token=${setPasswordToken}`;

      const headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };

      console.log("headers", headers);
      console.log("api url", url);
      axios.post(url, headers).then((response: any) => {
        if (
          response.data.status === true &&
          password === confirmPassword &&
          password !== ""
        ) {
          dispatch(setPosConnect(response.data.user.pos_id))
          // localStorage.setItem("PosAccount", response.data.user.pos_id);
          console.log("Response:", response.data);
          navigate("/helpUsToGrow");
          localStorage.setItem("IsLoginCreate", response.data.user);
          console.log(
            "response.data.user.pos_account_exists",
            response.data.user.pos_id
          );
        } else {
          console.log("Error Create Password");
        }
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  return (
    <div className="container-login text-start">
      <div className="main-container-login">
        <h2 className="miniosk-login">Don't stop now</h2>
        <p className="miniosk-login-desc" style={{ width: "29.55vw" }}>
          Strengthening Your Defense - Create A Powerful Password Now
        </p>
        <div>
          <h3 className="email-miniosk">Enter Password</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <input
              placeholder="Enter a password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              minLength={6}
              maxLength={15}
              className="miniosk-input"
            />
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex",
                }}
              >
                {showPassword ? (
                  <VisibilityOffIcon
                    className="mt-3 text-gray-400 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <VisibilityIcon
                    className="mt-3 text-gray-400 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 className="email-miniosk">Confirm Password</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <input
              placeholder="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              minLength={6}
              maxLength={15}
              className="miniosk-input"
            />
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "5px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  display: "flex",
                }}
              >
                {showPassword ? (
                  <VisibilityOffIcon
                    className="mt-3 text-gray-400 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <VisibilityIcon
                    className="mt-3 text-gray-400 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <button className="continue-button" onClick={RegisterSuccess}>
          Continue
        </button>
        <div className="kiosk_imgDiv">
          <img src={LOGO} alt="logo" style={{ width: "150px" }} />
        </div>
      </div>
      <div style={{ height: "100vh", overflow: "hidden", width: "60%" }}>
        <img
          src={BGIMAGE}
          alt="bg"
          style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default CreatePassword;
