import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import "./LoginStyles.css";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import BGIMAGE from "../../assets/images/background.jpg";
import LOGO from "../../assets/images/logo_main_img.png";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/base";
import { useDispatch } from "react-redux";
import { setIsLogin, setPosConnect, setUserDetails, setUserObject } from "../../store/reducers/AuthReducer";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import GoogleImage from "../../assets/images/google.png";
import axios from "axios";

let UserID: any;
function Login() {
  const navigate = useNavigate();
  const [email, setEmail]: any = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.removeItem("timer");
  }, []);

  const isValidEmail = (email: any) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const HandleLoginInitial = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await HelperModule().getMethod(
        ApiServices.base_URL + ApiServices.validate_email + email
      );
      console.log(response, "3");
      if (response.status === true && response.email_verification === true) {
        navigate("/password", { state: { email } });
        localStorage.setItem("email", email);
        console.log(response);
      } else if (email === "" || !isValidEmail(email)) {
        console.log(response, "2");
        enqueueSnackbar("Please Enter Email", {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else if (
        response.status === true &&
        response.email_verification === false
      ) {
        localStorage.setItem("UserCode", response.user_code);
        dispatch(
          setUserDetails({
            first_name: "",
            last_name: "",
            phone: "",
            email: email,
            back: false,
          })
        );
        console.log(email);
        navigate("/otp", { state: { email } });
      } else if (response.status === true) {
        dispatch(
          setUserDetails({
            first_name: "",
            last_name: "",
            phone: "",
            email: email,
            back: false,
          })
        );
        navigate("/createprofile", { state: { email } });
        console.log(response, "1");
        console.log(email, "createprofile");
      } else if (
        response.status === false &&
        response.message === "Email does not exist"
      ) {
        dispatch(
          setUserDetails({
            first_name: "",
            last_name: "",
            phone: "",
            email: email,
            back: false,
          })
        );
        navigate("/createprofile");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => handleGoogleLogin(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleGoogleLogin = async (user: any) => {
    console.log(user);
    try {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // setProfile(res.data);
          HandleLogin(res.data);
          console.log(res);
        })
        .catch((err) => console.log(err));
    } catch (error: any) {
      console.log(error);
    }
  };

  const HandleLogin = async (user: any) => {
    const Params = {
      login_type: "google",
      email: user.email,
      name: user.name,
      google_id: user.id,
      profile_image: user.picture
    };

    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.login,
        Params
      );
      console.log("USER===>", response?.user);
      if (response.status === true) {
        localStorage.setItem("referralCode", response.user?.referral_code);
        localStorage.setItem("IsLogin", response);
        localStorage.setItem("UserName", response.user?.name);
        localStorage.setItem("Email", response.user?.email);
        localStorage.setItem("UserCode", response.user?.user_code);
        dispatch(setPosConnect(response.user?.pos_account_status));
        dispatch(setUserObject(response?.user));
        console.log(response?.user, "PASSWORD");
        // localStorage.setItem("PosAccount", response.user?.pos_account_status);
        localStorage.setItem(
          "Subscription",
          response.user?.subscription_status
        );
        localStorage.setItem("UserID", response.user?.id);
        localStorage.setItem("Token", response.token);
        dispatch(setIsLogin(true));
      } else if (response.status === false) {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-login text-start">
      <div className="main-container-login">
        <h2 className="miniosk-login">Miniosk for business</h2>
        <p className="miniosk-login-desc">
          Create An Account Or Log In To Manage Your Business.
        </p>
        <h3 className="email-miniosk">Email</h3>
        <form onSubmit={HandleLoginInitial}>
          <input
            placeholder="example@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="miniosk-input"
          />
          {/* <button type="submit" className="continue-button">Continue</button> */}
          <Button
            type="submit"
            className="continue-button"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? "Loading..." : "Continue"}
          </Button>
        </form>
        <Button
          onClick={() => {
            // e.preventDefault(); // Prevent default button behavior
            login(); // Call the login function
          }}
          className="google-button"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={GoogleImage}
            alt="bg"
            style={{
              maxWidth: "40%",
              height: "40%",
              objectFit: "cover",
              marginRight: "2%",
            }}
          />
          Sign in with Google
        </Button>
        <div className="kiosk_imgDiv">
          <img src={LOGO} alt="logo" style={{ width: "150px" }} />
        </div>
      </div>
      <div style={{ height: "100vh", overflow: "hidden", width: "60%" }}>
        <img
          src={BGIMAGE}
          alt="bg"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default Login;
