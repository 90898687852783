import React, { Fragment, useEffect, useState } from "react";
import { useStyles } from "./UseStyles";
import { visuallyHidden } from "@mui/utils";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  TableSortLabel,
  Typography,
  LinearProgress,
} from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Modal from "@mui/material/Modal";
import Cross from "../assets/images/Cross.png";
import Emoji from "../assets/images/emoji.png";
import QrCode from "../assets/images/QrCodeColor.png";
import QrCodeBG from "../assets/images/Qrcode.png";
import Download from "../assets/images/Download.png";
import ApiServices from "../helpers/ApiServices";
import HelperModule from "../helpers/HelperModule";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { setMenusList } from "../store/reducers/ManageReducer";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import DataTable from "./DataTable";

function createData(
  menu_name: any,
  location: any,
  total_orders: any,
  device_id: any,
  device_name: any,
  status: any
) {
  return { menu_name, location, total_orders, device_id, device_name, status };
}

const rows: any = [
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
  createData("Heading", "Heading", "Heading", "Heading", "Heading", "Active"),
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: any = [
  {
    id: "menu_name",
    disablePadding: false,
    label: "Menu Name",
    sortable: true,
  },
  {
    id: "location_name",
    disablePadding: false,
    label: "Location",
    sortable: true,
  },

  {
    id: "total_devices",
    disablePadding: false,
    label: "No.Of Devices",
    sortable: true,
  },
  {
    id: "total_categories",
    disablePadding: false,
    label: "Categories",
    sortable: true,
  },
  {
    id: "total_items",
    disablePadding: true,
    label: "Items",
    sortable: true,
  },

  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sortable: true,
  },
];

function EnhancedTableHead(props: any) {
  const classes = useStyles();
  const { order, orderBy, onRequestSort, controller } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  function stableSort<T>(
    array: readonly T[],
    comparator: (a: T, b: T) => number
  ) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key
  ): (
    a: { [key in Key]: number | string | any },
    b: { [key in Key]: number | string | any }
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              className={classes.TableHeadTitle}
              active={controller.field === headCell.id} // orderBy === headCell.id
              // direction={orderBy === headCell.id ? order : "asc"}
              direction={controller.order === "asc" ? "asc" : "desc"}
              disabled={!headCell.sortable}
              onClick={createSortHandler(headCell.id)}
              IconComponent={(event) => (
                <ArrowDropDownRoundedIcon
                  {...event}
                  sx={{ color: "rgba(32, 28, 28, 0.32)" }}
                />
              )}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

let UserCodeData = localStorage.getItem("UserCode");

const MenusTable = ({ setFilterDisabled, selectedLocations }: any) => {
  const classes = useStyles();
  const { MenuList } = useSelector((state: any) => state.manageData);
  const { filteredLocations } = useSelector(
    (state: any) => state.locationDetails
  );

  const [recordsCount, setRecordsCount] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10,
    field: "",
    order: "",
  });

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<any>("menu_name");
  const [editModal, setEditModal] = React.useState(false);
  const [failedModal, setFailedModal] = React.useState(false);
  const navigate = useNavigate();
  const [menu, setMenu] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [selectedRow, setSelectedRow]: any = useState(null);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = MenuList.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(recordsCount / controller.rowsPerPage);
  const [filteredValues, setFilteredValues] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const ManageMenusData = async () => {
    try {
      setLoading(true);
      setFilterDisabled(true);
      const response = await HelperModule().getMethod(
        ApiServices.base_URL +
          ApiServices.manage_menus +
          `?user_code=${UserCodeData}&location_ids=${selectedLocations}&page=${controller.page}&field=${controller.field}&order=${controller.order}`
      );
      setFilterDisabled(false);
      if (response.status === true) {
        dispatch(setMenusList(response?.menus?.data || []));
        setRecordsCount(response?.menus?.total);
        setLoading(false);
      }
    } catch (error) {
      setFilterDisabled(false);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    ManageMenusData();
  }, [selectedLocations]);

  const HandleEdit = (row: any) => {
    // console.log(row.menu_code, "MENUCODE")
    setEditModal(true);
    setSelectedRow(row);
    setMenu(row?.menu_code);
  };

  const downloadQR = async () => {
    try {
      const response = await fetch(
        `https://api.miniosk.com/api/downloadQrCode/${menu}`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = "qr_code.png";
      link.click();
    } catch (error: any) {
      console.error("Error downloading QR code:", error.message);
      // Check if the error response is available
      if (error.response) {
        console.error("Error response:", await error.response.text());
      }
    }
  };

  const copyQrCodeLink = () => {
    navigator.clipboard
      .writeText(selectedRow.menu_qr_code_url)
      .then(() => {
        enqueueSnackbar("Qr code link copied successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      })
      .catch((err) => {
        console.error("Failed to copy QR code link: ", err);
      });
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any
  ) => {
    setController({
      ...controller,
      field: property,
      order:
        property !== controller.field
          ? "desc"
          : controller.order === "asc"
          ? "desc"
          : "asc",
      page: 1,
    });

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePaginationChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
    setController({
      ...controller,
      page: value,
    });
  };

  const visibleRows = React.useMemo(() => {
    stableSort(
      filteredValues.length > 0 ? filteredValues : MenuList,
      getComparator(order, orderBy)
    );
    return MenuList;
  }, [order, orderBy, MenuList, filteredValues]);

  useEffect(() => {
    setController({
      ...controller,
      page: 1,
    });
  }, [filteredLocations.length]);

  useEffect(() => {
    ManageMenusData();
  }, [controller]);

  const actionButton = (
    <button
      style={{
        height: "34px",
        width: "39.9px",
        borderRadius: "6px",
        border: 0,
        alignItems: "center",
        backgroundColor: "#8B5CFF1A",
        color: "#fff",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <img
        alt="Qr"
        src={QrCode}
        style={{ height: "16.77px", width: "16.77px" }}
      />
    </button> // Prevent row click event
  );

  return (
    <Fragment>
      {/* {loading ? (
        <div className="Loader-container">
          <CircularProgress disableShrink />
        </div>
      ) : ( */}
      <DataTable
        order={order}
        orderBy={orderBy}
        nPages={nPages}
        currentRecords={currentRecords}
        visibleRows={visibleRows}
        controller={controller}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        setController={setController}
        setCurrentPage={setCurrentPage}
        headCells={headCells}
        loading={loading}
        actionElement={actionButton}
        onActionClick={HandleEdit}
      />
      {/* <Box className={classes.TableMain}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size={"medium"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              controller={controller}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              ) : visibleRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body2">No Data Found</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.map((row: any, index: any) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index}
                      sx={{ cursor: "pointer" }}
                      onClick={() => setSelectedRow(row)}
                    >
                      <TableCell align="left" style={{}}>
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#8B5CFF",
                            fontFamily: "Poppins",
                            width: "130px",
                          }}
                          onClick={() =>
                            navigate(
                              `/menuDetails/${row.menu_name}/${row.menu_code}`,
                              {
                                state: {
                                  location_id: row.location_id,
                                  menu_QR_code_data: row.menu_qr_code_url,
                                },
                              }
                            )
                          }
                        >
                          {row?.menu_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ width: "128px" }}>
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                            width: "142px",
                          }}
                        >
                          {row?.location_name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ width: "165px" }}>
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                            width: "165px",
                          }}
                        >
                          {row?.total_devices}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" style={{ width: "128px" }}>
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                            width: "128px",
                          }}
                        >
                          {row?.total_categories}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#201C1C",
                            fontFamily: "Poppins",
                          }}
                        >
                          {row?.total_items}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.DashboarBodyCell}
                          sx={
                            {
                              fontWeight: 500,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              color: row?.status == 1 ? "#18BC46" : "#FF0000",
                            }
                          }
                        >
                          {row?.status == 1 ? "ACTIVE" : "INACTIVE"}
                        </Typography>
                        <div
                          onClick={() => HandleEdit(row.menu_code)}
                          style={{
                            marginLeft: "48px",
                            backgroundColor: "#8B5CFF1A",
                            height: "34px",
                            width: "39.9px",
                            borderRadius: "8px",
                            border: 0,
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            alt="Qr"
                            src={QrCode}
                            style={{ height: "16.77px", width: "16.77px" }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {nPages > 1 && (
          <Pagination
            page={controller.page}
            count={nPages}
            color="primary"
            size="small"
            onChange={handlePaginationChange}
            className={classes.paginationPrevNextBtn}
          />
        )}
      </Box> */}
      {/* )} */}

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            transition: "bottom 0.3s ease-in-out",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "33.38vw",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
            borderWidth: 0,
          }}
        >
          <div className="import-title-container">
            <h3 className="import-title">QR Details</h3>
            <div
              style={{
                backgroundColor: "#F34A4A29",
                height: "27.06px",
                width: "27.06px",
                borderRadius: "27.91px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <img
                onClick={() => setEditModal(false)}
                src={Cross}
                alt="Cross"
                style={{ width: "24px", height: "24px" }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <img
              alt="Qr"
              src={selectedRow?.menu_qr_code_url}
              style={{
                height: "152.44px",
                width: "152.44px",
                marginTop: "14px",
              }}
            />
            <div
              style={{
                marginTop: "21.5px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontWeight: "500",
                  fontSize: "12px",
                  fontFamily: "Poppins",
                  color: "#8B5CFF",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "150px",
                }}
              >
                {selectedRow?.menu_qr_code_url}
              </h3>
              <button
                style={{
                  height: " 3.10vh",
                  backgroundColor: "#8B5CFF66",
                  borderRadius: "4px",
                  border: 0,
                  fontSize: "10px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  marginLeft: "12px",
                }}
                onClick={copyQrCodeLink}
              >
                Copy
              </button>
            </div>
            <button className="download-qr" onClick={downloadQR}>
              <img
                src={Download}
                alt="Download"
                style={{ height: "12px", width: "8px", marginRight: "10px" }}
              />
              Download QR
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={failedModal}
        onClose={() => setFailedModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "44.8vw",
            height: "32.0vh",
            bgcolor: "background.paper",
            borderRadius: "8px",
            boxShadow: 24,
            padding: "16px",
          }}
        >
          <div className="success-container">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h2 className="success-header">Uh-oh!!</h2>
              <img
                alt="Emoji"
                src={Emoji}
                style={{ width: "30px", height: "30px", marginLeft: "14px" }}
              />
            </div>
            <p className="success-desc">
              You cant Changes menu when location is active
            </p>
            <button onClick={() => setFailedModal(false)} className="done-btn">
              Done
            </button>
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default MenusTable;
