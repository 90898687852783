import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useLocation, Link } from "react-router-dom";
import "./CreateProfile.css";
import LOGO from "../../assets/images/logo_main_img.png";
import BGIMAGE from "../../assets/images/background.jpg";
import HelperModule from "../../helpers/HelperModule";
import ApiServices from "../../helpers/ApiServices";
import { useParams } from "react-router-dom";
import "react-international-phone/style.css";
import MuiPhone from "./MuiPhone";
import { setUserDetails } from "../../store/reducers/AuthReducer";
import { useDispatch, useSelector } from "react-redux";

function CreateProfile() {
  const [firstName, setFirstName]: any = useState("");
  const [lastName, setLastName]: any = useState("");
  const [email, setEmail]: any = useState("");
  const [referral, setReferral] = useState("");
  const { id, mail } = useParams();
  const [phoneNumber, setPhoneNumber]: any = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked]: any = useState(false);

  const dispatch = useDispatch();
  const { userDetails } = useSelector((state: any) => state.Auth);

  useEffect(() => {
    setFirstName(userDetails.first_name);
    setLastName(userDetails.last_name);
    setPhoneNumber(userDetails.phone);
    setEmail(userDetails.email);
    sessionStorage.removeItem("timer");
    if (id) {
      setReferral(id);
    }
    if(mail){
      setEmail(mail)
    }
  }, []);

  const handleCheckboxChange = (e: any) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const Register = (e: any) => {
    const checkbox = document.getElementById(
      "onlyOption"
    ) as HTMLInputElement | null;
    const isCheckboxChecked = checkbox?.checked;

    if (!firstName || !isValidFirstName) {
      enqueueSnackbar("Please Enter Your First Name.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (firstName.length < 3) {
      enqueueSnackbar("First Name Should Not Be Minimum 3", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (!lastName || !isValidLastName) {
      enqueueSnackbar("Please Enter Your Last Name.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (lastName.length < 3) {
      enqueueSnackbar("Last Name Should Not Be Minimum 3", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    } else if (!email || !isValidEmail(email)) {
      enqueueSnackbar("Please Enter A Valid Email Address.", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        style: { fontFamily: "Poppins", fontWeight: "500" },
      });
    }
    // else if (phoneNumber.length < 10 || phoneNumber.length > 10) {
    //   enqueueSnackbar("Please Enter A Valid Phone Number.", { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }, style: { fontFamily: 'Poppins', fontWeight: '500' } });
    // }
    else if (!isCheckboxChecked) {
      enqueueSnackbar(
        "Please agree to the Terms of Service & Privacy Policy.",
        {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        }
      );
    } else {
      // navigate('/createPassword', { state: { firstName, lastName, email, phoneNumber } });
      localStorage.setItem("UserNameCreateProfile", firstName + " " + lastName);
      localStorage.setItem("emailCreateProfile", email);
      GetOtp(e);
    }
  };

  const isValidFirstName = (firstName: any) => {
    return firstName.length >= 4;
  };

  const isValidLastName = (lastName: any) => {
    return lastName.length >= 4;
  };

  const isValidEmail = (email: any) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  // const handlePhoneNumberChange = (e: any) => {
  //   const inputValue = e.target.value.replace(/[^\d]+/g, "");
  //   setPhoneNumber(inputValue);
  //   const isValid = /^[0-9]+$/.test(inputValue);
  //   if (!isValid) {
  //     enqueueSnackbar("Please Enter Numeric Characters.", {
  //       variant: "error",
  //       anchorOrigin: { vertical: "top", horizontal: "right" },
  //       style: { fontFamily: "Poppins", fontWeight: "500" },
  //     });
  //   }
  // };

  const GetOtp = async (e: any) => {
    e.preventDefault();
    const Params = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phoneNumber,
    };
    const dispatchDetails = {
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      email: email,
      back: true,
    };
    try {
      const response = await HelperModule().postMethod(
        ApiServices.base_URL + ApiServices.create_user_otp,
        Params
      );
      if (response.status === true) {
        enqueueSnackbar(response.message, {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
        setTimeout(() => {
          navigate("/otp", {
            state: { firstName, lastName, email, phoneNumber },
          });
        }, 1000);
        dispatch(setUserDetails(dispatchDetails));
        // localStorage.setItem("firstNameCreate", firstName);
        // localStorage.setItem("lastNameCreate", lastName);
        // localStorage.setItem("emailCreate", email);
        // localStorage.setItem("phoneCreate", phoneNumber);
        localStorage.setItem("UserCode", response.user.user_code);
        localStorage.setItem("Token", response.auth_token);
        localStorage.setItem("set_password_token", response.set_password_token);
      }
      // else if (response.Status === 422) {
      //   enqueueSnackbar(response.message, {
      //     variant: "error",
      //     anchorOrigin: { vertical: "top", horizontal: "right" },
      //     style: { fontFamily: "Poppins", fontWeight: "500" },
      //   });
      // }
      else if (response.status === false) {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      } else {
        enqueueSnackbar(response.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          style: { fontFamily: "Poppins", fontWeight: "500" },
        });
      }
    } catch (error) {
      // enqueueSnackbar("Invalid Mobile Number", {
      //   variant: "error",
      //   anchorOrigin: { vertical: "top", horizontal: "right" },
      //   style: { fontFamily: "Poppins", fontWeight: "500" },
      // });
      console.log("error catch", error);
    }
  };

  return (
    <div className="container-register">
      <div className="main-container-register">
        <div className="register-form" style={{ overflowY: "auto" }}>
          <h2 className="miniosk-register">
            Create Profile Miniosk for business
          </h2>
          <div>
            <h3 className="email-miniosk">First Name</h3>
            <input
              placeholder="Enter First Name"
              className="miniosk-input"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <h3 className="email-miniosk">Last Name</h3>
            <input
              placeholder="Enter Last Name"
              className="miniosk-input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div>
            <h3 className="email-miniosk">Email ID</h3>
            <input
              placeholder="example@gmail.com"
              className="miniosk-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <h3 className="email-miniosk">Phone Number</h3>
            <MuiPhone
              placeholder="Enter Phone Number"
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="miniosk-input-phone"
              // sx={{
              //   ".css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input": {
              //     borderRadius: "8px",
              //     padding: "0",
              //     height: "5.5vh",
              //   },
              // }}
            />
          </div>
          {referral && (
            <div>
              <h3 className="email-miniosk">Referal Code</h3>
              <input
                placeholder="Enter Referal Code"
                className="miniosk-input"
                value={referral}
                onChange={(e) => setReferral(e.target.value)}
                readOnly={true}
              />
            </div>
          )}
          <div className="checkboxDiv">
            <input
              type="checkbox"
              id="onlyOption"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            <div>
              <label htmlFor="onlyOption" className="radio-input">
                I agree with the <Link style={{ textDecoration: "underline", color:"black" }} to={"https://miniosk.com/terms-of-service.pdf"}>Terms of service</Link> & <Link style={{ textDecoration: "underline", color:"black" }} to={"https://miniosk.com/privacy-policy.pdf"}>Privacy policy</Link>.
              </label>
            </div>
          </div>
          <button className="continue-button" onClick={Register}>
            Continue
          </button>
        </div>

        <div className="kiosk_imgDiv- reg-logo">
          <img src={LOGO} alt="logo" style={{ height: "35px" }} />
        </div>
      </div>

      <div style={{ height: "100vh", overflow: "hidden", width: "60%" }}>
        <img
          src={BGIMAGE}
          alt="bg"
          style={{ maxWidth: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
    </div>
  );
}

export default CreateProfile;
